import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useEffect, useState } from 'react';
import Card from "components/card/Card"
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';

const localizer = momentLocalizer(moment);

const Overview = () => {
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const empId = sessionStorage.getItem('empId');
        const response = await axios.get(`https://api.cloudace.co.uk/fetch-events?empId=${empId}`);

        const formattedEvents = response.data.map(event => {
          const startTime = moment(`${event.aDate} ${event.aInTime}`, 'YYYY-MM-DD HH:mm:ss').toDate();
          const endTime = moment(`${event.aDate} ${event.aOutTime}`, 'YYYY-MM-DD HH:mm:ss').toDate();

          return {
            id: event.id,
            title: `${event.eFname} ${event.eLname}`,
            start: startTime,
            end: endTime,
            aStatus: event.aStatus,
            aInTimeRemark: event.aInTimeRemark,
            aLeaveStatus: event.aLeaveStatus
          };
        });

        setEvents(formattedEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };



  const eventStyleGetter = (event) => {
    let style = {
      backgroundColor: '',
      color: 'black',
    };

    // Check if the employee is on leave (aLeaveStatus === 3) first to prioritize color
    if (event.aLeaveStatus === 3) {
      style.backgroundColor = '#ffcccc'; // Light red shade for leave events
      style.color = 'black'; // Text color (adjust as needed)
    } else if (event.aStatus === 1) {
      style.backgroundColor = '#116361'; // Green shade for present events
      style.color = 'white'; // Text color (adjust as needed)
    } else if (event.aStatus === 2) {
      style.backgroundColor = 'red'; // Red shade for absent events
      style.color = 'white'; // Text color (adjust as needed)
    }

    return {
      style: style,
    };
  };


  const eventTimeRangeFormat = ({ start, end }, culture, local) => {
    const startTime = moment(start).format('h:mm A', local);
    const endTime = moment(end).format('h:mm A', local);
    return `${startTime} - ${endTime}`;
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500, margin: '50px' }}
          eventPropGetter={eventStyleGetter}
          onSelectEvent={handleEventClick}
          views={['month', 'week', 'day']}
          formats={{
            timeGutterFormat: 'h:mm A',
            eventTimeRangeFormat: eventTimeRangeFormat
          }}
        />

        <Modal isOpen={showModal} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Event Details</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {selectedEvent && (
                <div>
                  <p>Title: {selectedEvent.title}</p>
                  <p>Time: {eventTimeRangeFormat(selectedEvent)}</p>
                  {selectedEvent.aLeaveStatus === 3 && (
                    <p style={{ color: 'red' }}>Employee is on Leave</p>
                  )}
                  {selectedEvent.aStatus === 2 && (
                    <p style={{ color: 'red' }}>Employee is Absent</p>
                  )}
                  <h5>Agenda: {selectedEvent.aInTimeRemark}</h5>
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              {/* Add any footer content or actions here if needed */}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Card>
    </Box>

  );
};

export default Overview;
