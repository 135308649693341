import React, { useEffect, useState } from 'react';
import {
  Box,
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  Stack
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment'; // Import moment for date/time formatting

export default function UserReports() {
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [companiesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [empId, setEmpId] = useState(null);

  useEffect(() => {
    const empId = sessionStorage.getItem('empId');
    if (!empId) {
      history.push('/auth/sign-in');
      return;
    }
    setEmpId(empId);
    fetchCompanies(empId);
  }, [history]);

  const fetchCompanies = async (empId) => {
    try {
      const response = await axios.get(`https://api.cloudace.co.uk/fetch-events?empId=${empId}`);
      if (response.status === 200) {
        setCompanies(response.data);
      } else {
        console.error('Failed to fetch company data');
      }
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
  };

  const indexOfLastCompany = currentPage * companiesPerPage;
  const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
  const currentCompanies = companies.filter(company => {
    const {
      eFname,
      eMname,
      eLname,
      lName,
      aDate,
      aInTime,
      aOutTime,
      aInTimeRemark,
      aDefaultBreak
    } = company;

    const matchesSearchTerm =
      (eFname && eFname.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (eMname && eMname.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (eLname && eLname.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (lName && lName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (aDate && aDate.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (aInTime && aInTime.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (aOutTime && aOutTime.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (aInTimeRemark && aInTimeRemark.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (aDefaultBreak && aDefaultBreak.toString().toLowerCase().includes(searchTerm.toLowerCase()));

    return matchesSearchTerm;
  }).slice(indexOfFirstCompany, indexOfLastCompany);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (timestamp, isTime = false) => {
    if (isTime) {
      return moment(timestamp, 'HH:mm:ss').format('hh:mm A'); // Format time
    } else {
      return moment(timestamp).format('DD-MM-YYYY'); // Format date
    }
  };

  return (
    <Box pt={{ base: "130px", md: "px", xl: "80px" }}>
      <Link
        color='black'
        href='#'
        bg='inherit'
        borderRadius='inherit'
        fontWeight='bold'
        fontSize='34px'
        _active={{
          bg: 'inherit',
          transform: 'none',
          borderColor: 'transparent'
        }}
        _focus={{
          boxShadow: 'none'
        }}>
        My Dashboard
      </Link>
      <Card>
        <Table variant="striped" colorScheme="brand">
          <Thead>
            <Tr>
              <Th>SR</Th>
              <Th>NAME</Th>
              <Th>LOCATION</Th>
              <Th>DATE</Th>
              <Th>IN-TIME</Th>
              <Th>OUT-TIME</Th>
              <Th>Note</Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentCompanies.map((company, index) => (
              <Tr key={company.id}>
                <Td>{indexOfFirstCompany + index + 1}</Td>
                <Td>{company.eFname} {company.eMname} {company.eLname}</Td>
                <Td>{company.lName}</Td>
                <Td>{formatDate(company.aDate)}</Td>
                <Td>{formatDate(company.aInTime, true)}</Td>
                <Td>{formatDate(company.aOutTime, true)}</Td>
                <Td>{company.aInTimeRemark}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Stack direction="row" justify="center" mt={4}>
          {Array.from({ length: Math.ceil(companies.length / companiesPerPage) }, (_, index) => (
            <Button
              key={index}
              variant={currentPage === index + 1 ? "solid" : "outline"}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </Button>
          ))}
        </Stack>
      </Card>
    </Box>
  );
}
