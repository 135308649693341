
import {
    Box,
    Link,
    Grid,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    Button,
    Menu,
    MenuItem,
    MenuList,
    Badge,
    Stack,
    MenuButton
} from "@chakra-ui/react";
import Card from "components/card/Card"
import { NavLink } from 'react-router-dom';
import moment from 'moment'; // Import moment for date/time formatting

import React, { useState, useEffect } from 'react';
import { MdArrowDropDown, MdForkRight } from "react-icons/md";

export default function Overview() {
    const [leave, setLeave] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [locationsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [empId, setempId] = useState(null);

    useEffect(() => {
        const empId = sessionStorage.getItem('empId');

        if (empId === null) {
            window.location.href = '/login';
        }
        const fetchLeaveData = async () => {
            try {
                const empId = sessionStorage.getItem('empId');
                const response = await fetch(`https://api.cloudace.co.uk/fetch-leave?empId=${empId}`);
                if (response.ok) {
                    const data = await response.json();
                    setLeave(data);
                } else {
                    console.error('Failed to fetch leave data');
                }
            } catch (error) {
                console.error('Error fetching leave data:', error);
            }
        };

        fetchLeaveData();
    }, [empId]);

    // Get current leave
    const indexOfLastLeave = currentPage * locationsPerPage;
    const indexOfFirstLeave = indexOfLastLeave - locationsPerPage;

    const filteredLeave = leave.filter(leave =>
        leave.eFname && leave.eFname.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const currentLeave = filteredLeave.slice(indexOfFirstLeave, indexOfLastLeave);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset pagination to first page when searching
    };

    // Handle approve action
    const handleApprove = async (id) => {
        try {
            const response = await fetch(`https://api.cloudace.co.uk/update-leave/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ lStatus: 1 }) // Set lStatus to 1 (Accepted)
            });
            if (response.ok) {
                // Update the leave status in the state
                setLeave(leave.map(item => item.id === id ? { ...item, lStatus: 1 } : item));
            } else {
                console.error('Failed to update leave status');
            }
        } catch (error) {
            console.error('Error updating leave status:', error);
        }
    };

    // Handle reject action
    const handleReject = async (id) => {
        try {
            const response = await fetch(`https://api.cloudace.co.uk/update-leave/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ lStatus: 2 }) // Set lStatus to 2 (Rejected)
            });
            if (response.ok) {
                // Update the leave status in the state
                setLeave(leave.map(item => item.id === id ? { ...item, lStatus: 2 } : item));

            } else {
                console.error('Failed to update leave status');
            }
        } catch (error) {
            console.error('Error updating leave status:', error);
        }
        window.location.reload();
    };

    // Render status badge based on lStatus value
    const renderStatusBadge = (lStatus) => {
        let statusText;
        let badgeClass;

        switch (lStatus) {
            case 0:
                statusText = 'Action Pending';
                badgeClass = 'gray';
                break;
            case 1:
                statusText = 'Accepted';
                badgeClass = 'green';
                break;
            case 2:
                statusText = 'Rejected';
                badgeClass = 'red';
                break;
            default:
                statusText = '';
                badgeClass = '';
        }

        return <Badge variant="solid" colorScheme={badgeClass}>{statusText}</Badge>;
    };

    const formatDate = (timestamp, isTime = false) => {
        if (isTime) {
          return moment(timestamp, 'HH:mm:ss').format('hh:mm A'); // Format time
        } else {
          return moment(timestamp).format('DD-MM-YYYY'); // Format date
        }
      };
    return (

        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

            <Link
                color='black'
                href='#'
                bg='inherit'
                borderRadius='inherit'
                fontWeight='bold'
                fontSize='34px'
                _active={{
                    bg: 'inherit',
                    transform: 'none',
                    borderColor: 'transparent'
                }}
                _focus={{
                    boxShadow: 'none'
                }}>
                My Leave
            </Link>

            <Card>
                <Stack spacing={4} direction="row" align="center">

                    <Button variant="brand" size="sm" >
                        <NavLink to='leave-add' fontSize="sm">Add Leave</NavLink>
                    </Button>

                </Stack>
                {/* Main Fields */}
                <Table variant="striped" colorScheme="green">

                    <Thead>
                        <Tr>
                            <Th>SR</Th>
                            <Th>EMPLOYEE NAME</Th>
                            <Th>LEAVE TYPE</Th>
                            <Th>FROM DATE</Th>
                            <Th>TO DATE</Th>
                            <Th>REASON</Th>
                            <Th>STATUS</Th>
                        
                        </Tr>
                    </Thead>
                    <Tbody>
                        {currentLeave.map((leave, index) => (
                            <Tr key={leave.id}>
                                <Td>{indexOfFirstLeave + index + 1}</Td>
                                <Td>{leave.eFname} {leave.eMname} {leave.eLname}</Td>
                                <Td>{leave.lType}</Td>
                                <Td>{formatDate(leave.lFromDate)}</Td>
                                <Td>{formatDate(leave.lToDate)}</Td>
                                <Td>{leave.lReason}</Td>
                                <Td>{renderStatusBadge(leave.lStatus)}</Td>
                                
                            </Tr>
                        ))}


                    </Tbody>

                </Table>

            </Card>
        </Box>
    );
}
