import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import DefaultAuth from 'layouts/auth/Default';
import illustration from 'assets/img/auth/auth.png';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send login request to server
      const response = await fetch('https://api.cloudace.co.uk/login-employee', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });

      if (response.ok) {
        // Login successful, store company ID in session and redirect to dashboard
        const data = await response.json();
        sessionStorage.setItem('empId', data.empId); // Store user ID in session
        history.push('/admin/default');// Changed to use navigate function directly
        window.location.reload();
      } else {
        // Login failed, display error message
        setError('Incorrect email or password');
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w='100%'
        mx={{ base: 'auto', lg: '0px' }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection='column'
      >
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'
          >
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          direction='column'
          w={{ base: '100%', md: '420px' }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: 'auto', lg: 'unset' }}
          me='auto'
          mb={{ base: '20px', md: 'auto' }}
        >
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel htmlFor='email' color={textColor} mb='8px'>
                Email<span style={{ color: brandStars }}>*</span>
              </FormLabel>
              <Input
                id='email'
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormControl>
            <FormControl mt='24px'>
              <FormLabel htmlFor='password' color={textColor} mb='8px'>
                Password<span style={{ color: brandStars }}>*</span>
              </FormLabel>
              <InputGroup>
                <Input
                  id='password'
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <InputRightElement>
                  <Icon
                    as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    color={textColorSecondary}
                    _hover={{ cursor: 'pointer' }}
                    onClick={togglePasswordVisibility}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            {error && (
              <Text color='red.500' mt='2' fontSize='sm'>
                {error}
              </Text>
            )}
            <Button
              type='submit'
              variant='brand'
              mt='24px'
              w='100%'
              h='50px'
              fontSize='sm'
              fontWeight='500'
            >
              Sign In
            </Button>
          </form>
          <Flex justifyContent='space-between' alignItems='center' mt='24px'>


            <NavLink to='/auth/forgot-password'>
              <Text color={brandStars} fontSize='sm' fontWeight='500'>
                Forgot password?
              </Text>
            </NavLink>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
