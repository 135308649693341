import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdCalendarViewWeek,
  MdNote,
  MdNoteAdd,
  MdListAlt,
  MdLocationPin,
  MdPerson4,
  MdPending,
  MdOutbox,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import Calendar from "views/admin/Calendar";
import Attendance from "views/admin/Attendance";




import Leave from "views/admin/Employee/Leave";
import LeaveAdd from "views/admin/Employee/LeaveAdd";


import SignInCentered from "views/auth/signIn";
import ForgotPassword from "views/auth/signIn/ForgotPassword";
import ResetPassword from "views/auth/signIn/ResetPassword";
import { IoMdGitMerge } from "react-icons/io";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Calendar",
    layout: "/admin",
    icon: <Icon as={MdCalendarViewWeek} width="20px" height="20px" color="inherit" />,
    path: "/calendar",
    component: Calendar,
  },

  
  {
    name: "Leave",
    layout: "/admin",
    icon: <Icon as={MdPending} width="20px" height="20px" color="inherit" />,
    path: "/leave",
    component: Leave,
  },
  
  {
    name: "My Profile",
    layout: "/admin",
    path: "/profile/:id",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
    hidden: true,
  },
  {
    name: "Log out",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
    hidden: true,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ForgotPassword,
    hidden: true,
  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: "/reset-password/:token",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ResetPassword,
    hidden: true,
  },
  
  {
    name: "leave Add",
    layout: "/admin",
    icon: <Icon as={MdNote} width="20px" height="20px" color="inherit" />,
    path: "/leave-add",
    component: LeaveAdd,
    hidden: true,
  },

];

export default routes;
