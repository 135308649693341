
import {
    Box,
    Grid,
    FormControl,
    FormLabel,
    Input,
    Select,
    Button,
} from "@chakra-ui/react";
import Card from "components/card/Card"
import { useHistory } from 'react-router-dom';
import moment from 'moment'; // Import moment for date/time formatting

import React, { useState, useEffect } from 'react';
import { MdArrowDropDown, MdForkRight } from "react-icons/md";
import axios from 'axios';

export default function Overview() {
    const [formData, setFormData] = useState({
        lEmpId: '',       // Employee ID for leave
        lType: '',        // Leave type
        lFromDate: '',    // Leave start date
        lToDate: '',      // Leave end date
        lReason: ''       // Reason for leave
      });
    
      const [employee, setLocations] = useState([]);
      const [leavetype, setLeaveType] = useState([]);
      const history = useHistory();
      
      useEffect(() => {
        const empId = sessionStorage.getItem('empId');
    
        if (!empId) {
          // Redirect to login page if empId is not found
          window.location.href = '/login';
        } else {
          // Set lEmpId in the formData state to the retrieved empId
          setFormData({ ...formData, lEmpId: empId });
        }
    
        const fetchLocations = async () => {
          try {
            const response = await axios.get(`https://api.cloudace.co.uk/fetch-employee?empId=${empId}`);
            setLocations(response.data);
          } catch (error) {
            console.error('Error fetching locations:', error);
          }
        };
    
        const fetchType = async () => {
          try {
            const response = await axios.get('https://api.cloudace.co.uk/get-leavetype');
            setLeaveType(response.data);
          } catch (error) {
            console.error('Error fetching leavetype:', error);
          }
        };
    
        fetchLocations();
        fetchType();
      }, []); // Empty dependency array to run once on component mount
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const empId = sessionStorage.getItem('empId');
          const formDataWithEmpId = { ...formData, empId: empId };
          await saveFormDataToServer(formDataWithEmpId);
          history.push('/admin/leave');
        } catch (error) {
          console.error('Error saving data:', error);
        }
      };
    
      const saveFormDataToServer = async (data) => {
        try {
          const response = await axios.post('https://api.cloudace.co.uk/add-leave-user', data);
          console.log('Data saved successfully:', response.data);
        } catch (error) {
          console.error('Error saving data:', error);
        }
      };
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card>
                <form onSubmit={handleSubmit}>

                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <Input
                          type="hidden"
                          name="lEmpId"
                          value={formData.lEmpId}
                          onChange={handleChange}
                          required
                          className="form-control"
                          readOnly // Make the input readOnly to prevent user modification
                        />


                        <FormControl id="location">
                            <FormLabel>Select Leave Type</FormLabel>
                            <Select
                                name="lType"
                                value={formData.lType}
                                onChange={handleChange}
                                required
                                className='form-control'
                            >
                                <option>Select Any</option>
                                {leavetype.map(leavetype => (
                                    <option key={leavetype.id} value={leavetype.id}>{leavetype.lType} </option>
                                ))}
                            </Select>
                        </FormControl>



                        <FormControl id="dateTo">
                            <FormLabel>From Date</FormLabel>
                            <Input
                                type="date"
                                name="lFromDate"
                                value={formData.lFromDate}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="dateTo">
                            <FormLabel>To Date</FormLabel>
                            <Input
                                type="date"
                                name="lToDate"
                                value={formData.lToDate}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="dateTo">
                            <FormLabel>Reason</FormLabel>
                            <Input
                                type="text"
                                name="lReason"
                                value={formData.lReason}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>
                    </Grid>

                    <Button
                        mt={4}
                        colorScheme="brand"
                        type="submit"
                    >
                        Submit
                    </Button>
                </form>
            </Card>
        </Box>

    );
}
